export function isInViewport(el: HTMLElement) {
    if (window.getComputedStyle(el).display === 'none') {
        return false;
    }

    // See: https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    // Based on: https://github.com/Emmaccen/isElementInView/blob/main/view.js

    const rect = el.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.bottom > 0 && rect.top >= 0 && rect.top <= viewportHeight;
}
